import { addHeaders } from '../util/apiHelper';
import { appProperties } from '../util/appProperties';
import { ApiError } from '../util/errorClassess';

/**
 * Retrieves the order history for an e-certificate based on the provided parameters.
 *
 * @param {string} email - The email address associated with the e-certificate order history.
 * @param {number} pageNumber - The page number of the order history to retrieve.
 * @param {number} pageSize - The number of items per page in the order history.
 * @param {string} searchKeyword - The keyword to search for in the order history.
 * @param {Array<{key: string, value: string}>} filters - Array of key-value pair objects used for filtering the order history.
 * @param {object} instance - The MSAL instance to use for the request.
 * @return {Promise<object>} The order history data.
 */
export const getEcertificateOrderHistory = async (email, pageNumber, pageSize, searchKeyword, filters, instance) => {
  const requestBody = {
    pageNumber,
    pageSize,
    searchKeyword,
    filters
  };

  const apiEndpoint = `${appProperties.apiHost}/Consumers/getPurchaseHistoryList?email=${email}`;

  try {
    const headers = await addHeaders(true, instance);
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody)
    };
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized - Please log in.');
      }
      if (response.headers.get('Content-Type').startsWith('application/json')) {
        const errorResponse = await response.json();
        throw new ApiError(`API Error: get Ecertificate Order History`, errorResponse?.status);
      }
      throw new Error(`API Error: get Ecertificate Order History`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.log('ERR GetEcertificateOrderHistory: ', error);
    throw error;
  }
};
/**
 * Resends an existing e-certificate.
 *
 * @param {string} orderNumber - The orderNumber of the e-certificate to resend.
 * @return {Promise<any>} - A promise that resolves to the data returned from the API.
 */
export const resendExistingECertificate = async (orderNumber, instance) => {
  const queryParams = `orderNumber=${orderNumber}`;
  const apiEndpoint = `${appProperties.apiHost}/Consumers/resendEcertificate?${queryParams}`;

  try {
    const headers = await addHeaders(true, instance);
    const options = {
      method: 'GET',
      headers: headers
    };
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized - Please log in.');
      }
      if (response.headers.get('Content-Type').startsWith('application/json')) {
        const errorResponse = await response.json();
        throw new ApiError(`API Error: resending e-certificate`, errorResponse?.status);
      }
      throw new Error(`API Error: resending e-certificate`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.log('ERR ResendExistingECertificate: ', error);
    throw error;
  }
};
/**
 * Retrieves the order summary for an e-certificate checkout.
 *
 * @param {string} checkoutId - The ID of the checkout.
 * @return {Promise} A Promise that resolves to the order summary data.
 */
export const getEcertificateOrderSummary = async (checkoutId, instance) => {
  const queryParams = `checkoutId=${checkoutId}`;
  const apiEndpoint = `${appProperties.apiHost}/Consumers/getPurchaseSummary?${queryParams}`;

  try {
    const headers = await addHeaders(true, instance);
    const options = {
      method: 'GET',
      headers: headers
    };
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized - Please log in.');
      }
      if (response.headers.get('Content-Type').startsWith('application/json')) {
        const errorResponse = await response.json();
        throw new ApiError(`API Error: get EcertificateOrder Summary`, errorResponse?.status);
      }
      throw new Error(`API Error: get EcertificateOrder Summary`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.log('ERR GetEcertificateOrderSummary: ', error);
    throw error;
  }
};
/**
 * Calculates the price of carbon amount given the weight.
 *
 * @param {Number} weight - The weight of the carbon amount in kg.
 * @return {Promise} Returns a promise that resolves to the calculated price.
 */
export const calculateCarbonAmountPriceGivenWeight = async (weight) => {
  const queryParams = `weightInGrams=${weight * 1000}&region=${appProperties.applicationHostedRegion}`;
  const apiEndpoint = `${appProperties.apiHost}/Consumers/calculateAmount?${queryParams}`;
  const headers = await addHeaders(false, null);
  const options = {
    method: 'GET',
    headers: headers
  };

  try {
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new ApiError(errorResponse?.title, errorResponse?.status);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.log('ERR CalculateCarbonAmountPriceGivenWeight: ', error);
    throw error;
  }
};
/**
 * Retrieves the minimum amount of carbon that can be purchased.
 *
 * @return {Promise} Returns a promise that resolves to the minimum amount of carbon that can be purchased.
 */

export const getMinPurchasableCarbonAmount = async () => {
  const apiEndpoint = `${appProperties.apiHost}/Consumers/minCarbonAmount?region=${appProperties.applicationHostedRegion}`;
  const headers = await addHeaders(false, null);
  const options = {
    method: 'GET',
    headers: headers
  };

  try {
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new ApiError(`API Error: get Min Purchasable Carbon Amount`, errorResponse?.status);
    } else {
      const data = await response.text();
      return data;
    }
  } catch (error) {
    console.log('ERR GetMinPurchasableCarbonAmount: ', error);
    throw error;
  }
};
