import Card from 'react-bootstrap/Card';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import SendIcon from '../../assets/images/send.png';
import { resendExistingECertificate } from '../../services/eCertificateService';
import { useEffect, useState } from 'react';
import { ApiError } from '../../util/errorClassess';
import { toast } from 'react-toastify';
import BankDetailsInfo from '../BuyCertificates/BankDetailsInfo';
import { useMsal } from '@azure/msal-react';

export const OrderCard = ({
  orderNumber,
  price,
  referenceInstrument,
  quantity,
  purchaseDate,
  paymentReference,
  orderStatus,
  isDirectPay,
  isPaid,
  bankAccountCreatedOn,
  bankAccountIBAN,
  bankAccountLocation,
  bankAccountName,
  bankAccountNumber,
  bankAccountSortCode,
  bankAccountSwitfcode,
  bankAddress,
  bankName
}) => {
  const { instance } = useMsal();
  const [resendECertificateAPIError, setResendECertificateAPIError] = useState(false);
  const [resendECertificateAPIErrorMessage, setResendECertificateAPIErrorMessage] = useState(null);
  const [resendECertificateAPISuccess, setResendECertificateAPISuccess] = useState(false);
  const [resendECertificateInProgress, setResendECertificateInProgress] = useState(false);
  const [bankDetailsShown, setBankDetailsShown] = useState(false);

  const handleClose = () => setBankDetailsShown(false);
  const handleShow = () => setBankDetailsShown(true);

  const resendECertificateHandler = async () => {
    setResendECertificateInProgress(true);
    try {
      const response = await resendExistingECertificate(orderNumber, instance);
      if (response) {
        setResendECertificateAPISuccess(true);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        console.error(`${error.name} - ${error.message}`);
        console.error(`Status Code: ${error.statusCode}`);
        setResendECertificateAPIErrorMessage(error.message);
      } else {
        console.error('Unexpected error:', error);
        setResendECertificateAPIErrorMessage('Unexpected error');
      }
      setResendECertificateAPIError(true);
    } finally {
      setResendECertificateInProgress(false);
    }
  };

  useEffect(() => {
    if (resendECertificateAPIError)
      toast.error(resendECertificateAPIErrorMessage, {
        onClose: () => setResendECertificateAPIError(false)
      });
    if (resendECertificateAPISuccess)
      toast.success('Resending carbon cancellation certificate successful', {
        onClose: () => setResendECertificateAPISuccess(false)
      });
    // eslint-disable-next-line
  }, [resendECertificateAPIError, resendECertificateAPISuccess]);

  return (
    <Card className="order-history-card">
      <Card.Header>
        <div className="title-web">
          <Row>
            <Col>
              <p className="title-label">Order Number</p>
            </Col>
            <Col>
              <p className="title-value">{orderNumber}</p>
            </Col>
          </Row>
        </div>
        <div className="title-mobile">
          <Row>
            <Col>
              <p className="title-label">Order Number</p>
              <p className="title-value">{orderNumber}</p>
            </Col>
            <Col>
              <div className={orderStatus === 'Completed' ? 'status-badge' : 'status-badge status-pending'}>
                {orderStatus}
              </div>
            </Col>
          </Row>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg="6" sm="6" xs="6">
            <div className="data-label">
              <label className="body-label">Carbon Quantity</label>
              <p className="body-value">{quantity}</p>
            </div>
          </Col>
          <Col lg="6" sm="6" xs="6">
            <div className="data-label">
              <label className="body-label">Price</label>
              <p className="body-value">£{price}</p>
            </div>
          </Col>
          <Col lg="12" sm="12" xs="12">
            <div className="data-label">
              <label className="body-label">Reference Instrument</label>
              <p className="body-value">{referenceInstrument}</p>
            </div>
          </Col>
          <Col lg="12" sm="12" xs="12">
            <div className="data-label">
              <label className="body-label">Purchased Date</label>
              <p className="body-value">
                {new Date(purchaseDate).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </p>
            </div>
          </Col>
          {isDirectPay && (
            <Col lg="12" sm="12" xs="12">
              <div className="data-label">
                <label className="body-label">Payment Reference</label>
                <p className="body-value">{paymentReference}</p>
              </div>
            </Col>
          )}
          {isDirectPay && !isPaid && (
            <Col lg="12" sm="12" xs="12">
              <div className="data-label">
                <Button variant="secondary" onClick={handleShow}>
                  Show Bank Details
                </Button>
                <Modal className="mdl-bank-acc-detail" show={bankDetailsShown} onHide={handleClose} backdrop="static">
                  <Modal.Header closeButton> Bank Account Information </Modal.Header>
                  <Modal.Body className="p-5">
                    <BankDetailsInfo
                      bankAccountIBAN={bankAccountIBAN}
                      bankAccountLocation={bankAccountLocation}
                      bankAccountName={bankAccountName}
                      bankAccountNumber={bankAccountNumber}
                      bankAccountSortCode={bankAccountSortCode}
                      bankAccountSwitfcode={bankAccountSwitfcode}
                      bankAddress={bankAddress}
                      bankName={bankName}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          )}
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className={orderStatus === 'Completed' ? 'status-button-wrapper' : 'status-button-wrapper pending-status'}>
          <div className="status-badge">{orderStatus}</div>
          <div className="resend-btn">
            {orderStatus === 'Completed' && (
              <Button onClick={() => resendECertificateHandler()} disabled={resendECertificateInProgress}>
                <img src={SendIcon} alt="send-btn-icon" /> Resend Certificate
              </Button>
            )}
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};
