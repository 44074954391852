export const Constants = {
  claims: 'claims',
  token: 'token'
};

export const purchasedECertificateOrderStatus = Object.freeze({
  ALL: 'All',
  ORDER_PENDING: 'Order Pending',
  COMPLETED: 'Completed',
  PAYMENT_PENDING: 'Payment Pending'
});

export const countryCodes = {
  UNITED_KINGDOM: 'GB',
  AUSTRALIA: 'AU',
  ELSEWHERE: 'ELSEWHERE'
};

export const iSBusinessOrIndividualOptions = {
  BUSINESS: 'business',
  INDIVIDUAL: 'individual',
  None: ''
};

export const paymentOptions = {
  DIRECT_BANK_DEPOSIT: 'bankDeposit',
  STRIPE: 'stripe'
};
