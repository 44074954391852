import { useState, useEffect, useReducer, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { OrderCard } from './OrderCard';
import { Navigate, useNavigate } from 'react-router';
import darkLogo from '../../assets/images/logo_black.svg';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { getEcertificateOrderHistory } from '../../services/eCertificateService';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import CheveronDown from '../../assets/images/dropdown-cheveron.png';
import NoResultFoundIcon from '../../assets/images/No-result found Icon.png';
import OrderHistoryEmptyIcon from '../../assets/images/Order-histrory-empty-icon.png';
import BackBtn from '../../assets/images/back-btn.png';
import { convertUnits } from '../../common/formatter';
import { ApiError } from '../../util/errorClassess';
import { purchasedECertificateOrderStatus } from '../../util/constants';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import AppContext from '../../contexts/AppContext';
import { FaUser } from 'react-icons/fa';
import { FaFileDownload } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';

const orderHistoryStateReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_ORDERS_INIT':
      return { ...state, isLoading: true, error: null };
    case 'FETCH_ORDERS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: [...state.data, ...action.payload.paginatedList],
        currentPage: action.payload.pageNumber,
        nextPage:
          Math.ceil(action.payload.totalCount / action.payload.pageCount) > action.payload.pageNumber
            ? Number(action.payload.pageNumber) + 1
            : Number(action.payload.pageNumber),
        hasMore:
          Math.ceil(action.payload.totalCount / action.payload.pageCount) > action.payload.pageNumber ? true : false
      };
    case 'SEARCH_ORDERS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: [...action.payload.paginatedList],
        currentPage: action.payload.pageNumber,
        nextPage:
          Math.ceil(action.payload.totalCount / action.payload.pageCount) > action.payload.pageNumber
            ? Number(action.payload.pageNumber) + 1
            : Number(action.payload.pageNumber),
        hasMore:
          Math.ceil(action.payload.totalCount / action.payload.pageCount) > action.payload.pageNumber ? true : false
      };
    case 'FETCH_ORDERS_FAILURE':
      return { ...state, isLoading: false, error: action.payload };
    case 'FETCH_ORDERS_FAILURE_RESET':
      return { ...state, error: null };
    default:
      return state;
  }
};

const mapOrderStatusToFilterObject = (status) => {
  switch (status) {
    case purchasedECertificateOrderStatus.PAYMENT_PENDING:
      return [{ key: 'status', value: 'paymentPending' }];
    case purchasedECertificateOrderStatus.ORDER_PENDING:
      return [{ key: 'status', value: 'orderPending' }];
    case purchasedECertificateOrderStatus.COMPLETED:
      return [{ key: 'status', value: 'completed' }];
    default:
      return [];
  }
};

const OrderHistory = ({ userEmail }) => {
  const { instance } = useMsal();
  const applicationContext = useContext(AppContext);
  const { isECertificateFeatureAllowed } = applicationContext;
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    threshold: 0
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [orderHistoryState, dispatch] = useReducer(orderHistoryStateReducer, {
    data: [],
    currentPage: 1,
    nextPage: 1,
    isLoading: false,
    error: null,
    hasMore: true
  });
  const [orderNumber, setOrderNumber] = useState('');
  const [orderStatus, setOrderStatus] = useState(purchasedECertificateOrderStatus.ALL);
  const debouncedOrderNumber = useDebounce(orderNumber, 1000);

  useEffect(() => {
    const fetchOrderHistoryData = async () => {
      try {
        dispatch({ type: 'FETCH_ORDERS_INIT' });

        const email = userEmail;
        const pageNumber = orderHistoryState?.nextPage;
        const pageSize = isTabletOrMobile ? 3 : 10; // change page size on mobile devices
        const searchKeyword = debouncedOrderNumber;
        const orderStatusFilter = mapOrderStatusToFilterObject(orderStatus);

        const data = await getEcertificateOrderHistory(
          email,
          pageNumber,
          pageSize,
          searchKeyword,
          orderStatusFilter,
          instance
        );
        dispatch({ type: 'FETCH_ORDERS_SUCCESS', payload: data });
      } catch (error) {
        if (error instanceof ApiError) {
          console.error(`${error.name} - ${error.message}`);
          console.error(`Status Code: ${error.statusCode}`);
          dispatch({ type: 'FETCH_ORDERS_FAILURE', payload: error.message });
        } else {
          console.error('Unexpected error:', error);
          dispatch({ type: 'FETCH_ORDERS_FAILURE', payload: error?.message });
        }
      }
    };

    if (inView && !orderHistoryState.isLoading && orderHistoryState.hasMore) {
      fetchOrderHistoryData();
    }
    // eslint-disable-next-line
  }, [inView]);

  useEffect(() => {
    const fetchOrderHistoryData = async () => {
      try {
        dispatch({ type: 'FETCH_ORDERS_INIT' });

        const email = userEmail;
        const pageNumber = 1;
        const pageSize = isTabletOrMobile ? 3 : 10; // change page size on mobile devices
        const searchKeyword = debouncedOrderNumber;
        const orderStatusFilter = mapOrderStatusToFilterObject(orderStatus);

        const data = await getEcertificateOrderHistory(
          email,
          pageNumber,
          pageSize,
          searchKeyword,
          orderStatusFilter,
          instance
        );
        dispatch({ type: 'SEARCH_ORDERS_SUCCESS', payload: data });
      } catch (error) {
        if (error instanceof ApiError) {
          console.error(`${error.name} - ${error.message}`);
          console.error(`Status Code: ${error.statusCode}`);
          dispatch({ type: 'FETCH_ORDERS_FAILURE', payload: error.message });
        } else {
          console.error('Unexpected error:', error);
          dispatch({ type: 'FETCH_ORDERS_FAILURE', payload: error?.message });
        }
      }
    };
    fetchOrderHistoryData();

    // eslint-disable-next-line
  }, [debouncedOrderNumber, orderStatus]);

  useEffect(() => {
    if (orderHistoryState?.error)
      toast.error(orderHistoryState?.error, {
        onClose: () => resetFetchOrderFailure()
      });
  }, [orderHistoryState]);

  const handleBuyECertificateBtnClick = () => {
    navigate('/buyCertificate');
  };
  const handleOnChangeSearch = (e) => {
    setOrderNumber(e.target.value);
  };
  const goToMyProfile = async () => {
    navigate('/myprofile');
  };

  const resetFetchOrderFailure = () => {
    dispatch({ type: 'FETCH_ORDERS_FAILURE_RESET' });
  };

  const NoResultsFoundMessage = () => (
    <div className="wr-message-image mt-5">
      <div className="wr-image">
        <img src={NoResultFoundIcon} alt="Order-History-Empty-Icon" className="message-img" />
      </div>
      <label className="wr-title1-empty mt-3">No results found!</label>
      <label className="wr-title2-empty mt-1">
        We cannot find the information you are searching for. <br />
        Please make sure the input information is correct!
      </label>
    </div>
  );

  const NoDataExistsMessage = () => (
    <div className="wr-message-image mt-5">
      <div className="wr-image">
        <img src={OrderHistoryEmptyIcon} alt="Order-History-Empty-Icon" className="message-img" />
      </div>
      <label className="wr-title1-empty mt-3">Your Carbon Cancellation Certificate Order History is Empty</label>
      <label className="wr-title2-empty mt-1">Looks like you haven’t bought any certificates yet</label>
    </div>
  );

  const handleOnSelectOrderStatus = (eventKey, event) => {
    setOrderStatus(eventKey);
  };

  const goToHome = () => {
    navigate('/');
  };

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="container-fluid h-100">
      <div className="order-histroty">
        <div className="order-history-wrapper">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={darkLogo} alt="dark-logo" className="logo-img" style={{ width: 123 }} />
            </Link>
            {/* <Button className="btn back-btn" variant="link" onClick={goToHome}>
              <img src={BackBtn} alt="back-btn" />
            </Button> */}
          </div>
          <div className="orderh-histroy-btn-wrapper">
            <div className="home-nav-wrapper ms-3">
              <div className="web-buttons">
                <AuthenticatedTemplate>
                  {isECertificateFeatureAllowed && (
                    <Button onClick={handleBuyECertificateBtnClick} className="order-back-btn">
                      Buy Carbon Cancellation Certificate
                    </Button>
                  )}
                  <Button onClick={navigateToDashboard} className="ms-3 btn-outline icon-left-btn">
                    Claimed Carbon (B2C)
                  </Button>
                  <button onClick={goToMyProfile} className="ms-3 btn-outline icon-left-btn">
                    My Profile
                  </button>
                </AuthenticatedTemplate>
              </div>
              <div className="mobile-buttons">
                <AuthenticatedTemplate>
                  {isECertificateFeatureAllowed && (
                    <Button onClick={handleBuyECertificateBtnClick} className="round-button ms-2">
                      <FaFileDownload />
                    </Button>
                  )}
                  <Button onClick={navigateToDashboard} className="round-button ms-2">
                    <BiTransfer />
                  </Button>
                  <Button onClick={goToMyProfile} className="round-button ms-2">
                    <FaUser />
                  </Button>
                </AuthenticatedTemplate>
              </div>
            </div>
          </div>
        </div>
        <div className="wr-order-histroy pt-5 mt-5">
          <div className="">
            <div className="order-hsitory-info">
              <div className="icon-side">
                <MdOutlineInfo />
              </div>

              <p>
                Certificates will be resent to the email address associated with your account. If you wish them to be
                sent to a different address or for other support requests, please email
                <a href="mailto:support@c2zero.net">support@c2zero.net</a>.
              </p>
            </div>
          </div>
          <div className="wr-search sticky-top">
            <h1 className="order-history-title pt-3">Certificate Order History</h1>
            <div className="order-history-button">
              <input
                type="text"
                placeholder="Search by order number"
                className="order-histroy-search mt-3 mb-1 me-3"
                onChange={handleOnChangeSearch}
              />
              <Dropdown onSelect={handleOnSelectOrderStatus}>
                <DropdownButton
                  id="order-status-dropdown-button"
                  className="order-status-dropdown-button"
                  title={
                    <>
                      <div className="dropdown-text">{orderStatus}</div>
                      <div className="dropdown-icon-sdie">
                        <div className="dropdown-vr-line"></div>
                        <img src={CheveronDown} alt="cheveron" className="dropdown-cheveron" />
                      </div>
                    </>
                  }
                >
                  <Dropdown.Item eventKey={purchasedECertificateOrderStatus.ALL}>All</Dropdown.Item>
                  <Dropdown.Item eventKey={purchasedECertificateOrderStatus.COMPLETED}>Completed</Dropdown.Item>
                  <Dropdown.Item eventKey={purchasedECertificateOrderStatus.ORDER_PENDING}>Order Pending</Dropdown.Item>
                  <Dropdown.Item eventKey={purchasedECertificateOrderStatus.PAYMENT_PENDING}>
                    Payment Pending
                  </Dropdown.Item>
                </DropdownButton>
              </Dropdown>
            </div>
          </div>

          <div className="wr-order-history-card mt-3">
            <div className="main-card-container">
              {orderHistoryState.data.map((certificate) => (
                <OrderCard
                  key={certificate?.orderNumber}
                  orderNumber={certificate?.orderNumber}
                  price={certificate?.price}
                  referenceInstrument={certificate?.referenceInstrument}
                  quantity={convertUnits(certificate?.gramsQuantity, 'g')}
                  purchaseDate={certificate?.purchaseDate}
                  paymentReference={certificate?.paymentReference}
                  isDirectPay={certificate?.isDirectPay}
                  isPaid={certificate?.isPaid}
                  orderStatus={certificate?.orderStatus}
                  bankAccountCreatedOn={certificate?.bankAccountCreatedOn}
                  bankAccountIBAN={certificate?.bankAccountIBAN}
                  bankAccountLocation={certificate?.bankAccountLocation}
                  bankAccountName={certificate?.bankAccountName}
                  bankAccountNumber={certificate?.bankAccountNumber}
                  bankAccountSortCode={certificate?.bankAccountSortCode}
                  bankAccountSwitfcode={certificate?.bankAccountSwitfcode}
                  bankAddress={certificate?.bankAddress}
                  bankName={certificate?.bankName}
                />
              ))}
              <div style={{ border: '15px solid transparent' }} ref={ref}></div>{' '}
            </div>
            {orderHistoryState.isLoading && orderHistoryState.data.length !== 0 && (
              <div className="spinner-loader-wrapper">
                <Spinner animation="border" role="status">
                  {' '}
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {orderHistoryState.error && <div>Error: {orderHistoryState.error}</div>}
            {!orderHistoryState.hasMore && orderHistoryState.data.length !== 0 && (
              <div className="list-end-message">You’ve reached the end of the list</div>
            )}
            {!orderHistoryState.isLoading &&
              orderHistoryState.data.length === 0 &&
              debouncedOrderNumber === '' &&
              orderStatus === purchasedECertificateOrderStatus.ALL && <NoDataExistsMessage />}
            {!orderHistoryState.isLoading &&
              orderHistoryState.data.length === 0 &&
              ((debouncedOrderNumber !== '' && orderStatus !== purchasedECertificateOrderStatus.ALL) ||
                (debouncedOrderNumber !== '' && orderStatus === purchasedECertificateOrderStatus.ALL) ||
                (debouncedOrderNumber === '' && orderStatus !== purchasedECertificateOrderStatus.ALL)) && (
                <NoResultsFoundMessage />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderHistory;

export const OrderHistoryAuthenticatedWrapper = () => {
  const { instance } = useMsal();
  if (instance.getActiveAccount()?.idTokenClaims?.otherMails[0]) {
    return (
      <div className="verify-guest">
        {' '}
        <OrderHistory userEmail={instance.getActiveAccount()?.idTokenClaims.otherMails[0]} />
      </div>
    );
  } else {
    return <Navigate to="/" replace={true} />;
  }
};
