/**
 * Format the given price to a string representation with the specified number of decimal places.
 * @param {number} price - The price to be formatted.
 * @param {number} decimalPlaces - The number of decimal places to round the price to.
 * @returns {string} A string representation of the formatted price.
 *                   If price or decimalPlaces is not a number or is NaN, returns 'Invalid Price' or 'Invalid Decimal Places' accordingly.
 */
export const formatPrice = (price, decimalPlaces) => {
  if (typeof price !== 'number' || isNaN(price)) {
    return 'Invalid Price';
  }

  if (typeof decimalPlaces !== 'number' || isNaN(decimalPlaces)) {
    return 'Invalid Decimal Places';
  }

  // Round the price to the specified number of decimal places
  const roundedPrice = price.toFixed(decimalPlaces);

  return roundedPrice;
};
