import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Link, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { handleLoginFromBuyCertificatePage, handleSignup } from '../../services/authService';
import WhiteLogo from '../../assets/images/logo_white.svg';
import BlackLogo from '../../assets/images/logo_black.svg';
import FaqAccordian from './FaqAccordian';
import Closebtn from '../../assets/images/Close.png';
import { MdOutlineCreditCard } from 'react-icons/md';
import { BiTransferAlt } from 'react-icons/bi';
import Spinner from 'react-bootstrap/Spinner';

import { createPaymentIntent, makeDirectBankTransfer } from '../../services/stripeService';
import {
  calculateCarbonAmountPriceGivenWeight,
  getMinPurchasableCarbonAmount
} from '../../services/eCertificateService';
import { useMediaQuery } from 'react-responsive';
import ForwardArrow from '../../assets/images/arrow-forward.png';
import VisaCard from '../../assets/images/visa.svg';
import MasterCard from '../../assets/images/master.svg';
import AmexCard from '../../assets/images/amex.svg';
import { MdInfoOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { appProperties } from '../../util/appProperties';
import { countryCodes, iSBusinessOrIndividualOptions, paymentOptions } from '../../util/constants';
import { formatPrice } from '../../util/helpers';
import userIcon from '../../assets/images/user-icon.svg';
import { FaUser, FaHistory } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';

const BuyCarbonCertificateForm = () => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [overlayWidth, setOverlayWidth] = useState(0);
  const [paymentAPIError, setPaymentAPIError] = useState(false);
  const [minPurchasableCarbonAmount, setMinPurchasableCarbonAmount] = useState(100);
  const [minPurchasableCarbonAmountAPIInProgress, setMinPurchasableCarbonAmountAPIInProgress] = useState(true);

  const [buyECertificateDetails, saveBuyECertificateDetails] = useLocalStorage('buyECertificateDetails', null);
  const [guestUserEmail] = useLocalStorage('guestUserEmail', null);

  const applicationUserEmail = instance.getActiveAccount()?.idTokenClaims.otherMails[0];
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [priceCalcAPIError, setPriceCalcAPIError] = useState(null);
  const isAuthenticated = useIsAuthenticated();

  const initialFormValues = useMemo(
    () =>
      buyECertificateDetails
        ? {
            carbonQuantity: buyECertificateDetails?.carbonQuantity || '',
            currency: 'GBP',
            price: buyECertificateDetails?.price || '',
            email: buyECertificateDetails?.email || '',
            confirmEmail: buyECertificateDetails?.confirmEmail || '',
            newsletter: buyECertificateDetails?.newsletter || false,
            acceptTerms: buyECertificateDetails?.acceptTerms || false,
            recipientName: buyECertificateDetails?.recipientName || '',
            isECertificateGifting: buyECertificateDetails?.isECertificateGifting,
            businessLocatedCountry: buyECertificateDetails?.businessLocatedCountry || '',
            iSBusinessOrIndividual: buyECertificateDetails?.iSBusinessOrIndividual || '',
            vatExemption: buyECertificateDetails?.vatExemption || '',
            vatRegistrationNumber: buyECertificateDetails?.vatRegistrationNumber || '',
            businessName: buyECertificateDetails?.businessName || '',
            customerName: buyECertificateDetails?.customerName || '',
            IsAutomaticallyLogCarbon: buyECertificateDetails?.IsAutomaticallyLogCarbon || false
          }
        : {
            carbonQuantity: '',
            currency: 'GBP',
            price: '',
            email: '',
            confirmEmail: '',
            newsletter: false,
            acceptTerms: false,
            recipientName: '',
            isECertificateGifting: false,
            businessLocatedCountry: '',
            iSBusinessOrIndividual: '',
            vatExemption: '',
            vatRegistrationNumber: '',
            businessName: '',
            customerName: ''
          },
    [buyECertificateDetails]
  );
  useEffect(() => {
    const getMinCarbonAmount = async () => {
      try {
        const response = await getMinPurchasableCarbonAmount();
        if (response) {
          setMinPurchasableCarbonAmount(parseFloat(response));
        }
      } catch (error) {
        console.log('ERRGetMinPurchasableCarbonAmount: ', error);
      } finally {
        setMinPurchasableCarbonAmountAPIInProgress(false);
      }
    };
    getMinCarbonAmount();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      carbonQuantity: Yup.number()
        .min(minPurchasableCarbonAmount, `Minimum quantity should be ${minPurchasableCarbonAmount} Kg`)
        .required('Quantity is required'),
      acceptTerms: Yup.boolean().oneOf([true], 'You must accept the terms of service and privacy policy'),
      recipientName: Yup.string().required('Business or individual name is required'),
      email: Yup.string().when('isECertificateGifting', {
        is: true,
        then: () =>
          Yup.string()
            .email('Invalid email')
            .required('Receiver email is required')
            .test(
              'isNotApplicationUserEmail',
              'The email cannot be the same as the one currently associated with your account.',
              function (value, context) {
                const { isECertificateGifting } = context.parent;
                if (isECertificateGifting && value === applicationUserEmail) {
                  return false;
                }
                return true;
              }
            )
      }),
      confirmEmail: Yup.string().when('isECertificateGifting', {
        is: true,
        then: () =>
          Yup.string()
            .email('Invalid email')
            .oneOf([Yup.ref('email')], 'Emails should match')
            .required('Receiver email is required')
      }),
      isECertificateGifting: Yup.boolean().required('Please select an option'),
      businessLocatedCountry: Yup.string().required('Please select a country'),
      iSBusinessOrIndividual: Yup.string().when('businessLocatedCountry', {
        is: countryCodes.UNITED_KINGDOM,
        then: () => Yup.string().required('Please select an option')
      }),
      vatExemption: Yup.string().when(['businessLocatedCountry', 'iSBusinessOrIndividual'], {
        is: (businessLocatedCountry, iSBusinessOrIndividual) =>
          businessLocatedCountry === countryCodes.UNITED_KINGDOM &&
          iSBusinessOrIndividual === iSBusinessOrIndividualOptions.BUSINESS,
        then: () => Yup.string().required('Please select one option')
      }),
      businessName: Yup.string().when('vatExemption', {
        is: 'yes',
        then: () =>
          Yup.string()
            .required('Business name is required')
            .matches(/^[a-zA-Z ]*$/, 'Invalid business name format')
            .max(30, 'Business name must be at most 30 characters long')
      }),
      customerName: Yup.string().required('Contact name is required'),
      vatRegistrationNumber: Yup.string().when('vatExemption', {
        is: 'yes',
        then: () => Yup.string().required('VAT registration or exemption identifier is required')
      }),
      paymentOption: Yup.string().required('Payment option is required')
    });
  }, [applicationUserEmail, minPurchasableCarbonAmount]);

  const handleJoinc2zero = () => {
    handleSignup(instance, isAuthenticated, inProgress);
  };
  const handleSignIn = () => {
    handleLoginFromBuyCertificatePage(instance, isAuthenticated, inProgress);
  };
  const handleContineAsGuest = (formValues) => {
    const { email, confirmEmail, ...restFormValues } = formValues;
    saveBuyECertificateDetails(restFormValues);
    navigate('/orderHistory');
  };
  const openOverlay = () => {
    setOverlayWidth(100);
  };
  const openOverlayMobile = () => {
    setOverlayWidth(100);
  };
  const closeOverlay = () => {
    setOverlayWidth(0);
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    saveBuyECertificateDetails(values);
    // If user is authenticated or guest user email is available
    if (instance.getActiveAccount()) {
      const taxCalculationHelperObject = {
        applicationHostedRegion: appProperties.applicationHostedRegion,
        businessLocatedCountry: values.businessLocatedCountry,
        vatRegistrationNumber:
          appProperties.applicationHostedRegion === countryCodes.UNITED_KINGDOM &&
          values.businessLocatedCountry === countryCodes.UNITED_KINGDOM &&
          values.iSBusinessOrIndividual === iSBusinessOrIndividualOptions.BUSINESS &&
          values.vatExemption === 'yes'
            ? values.vatRegistrationNumber
            : null
      };
      switch (values.paymentOption) {
        case paymentOptions.STRIPE:
          try {
            let clientSecret;
            let paymentIntentId;
            const paymentIntentResponse = await createPaymentIntent(
              values.recipientName.trim(),
              applicationUserEmail,
              values.isECertificateGifting ? values.email : applicationUserEmail,
              values.carbonQuantity,
              values.newsletter,
              taxCalculationHelperObject.applicationHostedRegion,
              taxCalculationHelperObject.businessLocatedCountry,
              taxCalculationHelperObject.vatRegistrationNumber,
              values.customerName.trim(),
              values.businessName.trim(),
              !values.isECertificateGifting && values.IsAutomaticallyLogCarbon ? true : false,
              instance
            );
            if (paymentIntentResponse && paymentIntentResponse?.clientSecret) {
              clientSecret = paymentIntentResponse.clientSecret;
              paymentIntentId = paymentIntentResponse.paymentIntentId;
            } else {
              throw new Error('Payment intent response is undefined or missing result.');
            }
            navigate('/checkout', {
              state: {
                clientSecret: clientSecret,
                carbonQuantity: values.carbonQuantity,
                price: paymentIntentResponse?.priceWithTax,
                paymentIntentId: paymentIntentId,
                tax: paymentIntentResponse?.tax,
                priceWithTaxAndProcessingFee: paymentIntentResponse?.priceWithTaxAndProcessingFee,
                processingFee: paymentIntentResponse?.processingFee,
                isNotEnoughCarbon: paymentIntentResponse?.isNotEnoughCarbon,
                netPrice: paymentIntentResponse?.netPrice,
                processingFeePercentage: paymentIntentResponse?.processingFeePercentage,
                defaultNumericalValueProcessingFee: paymentIntentResponse?.defaultNumericalValueProcessingFee
              }
            });
          } catch (error) {
            console.error('Error fetching payment intent:', error);
            setPaymentAPIError(true);
          }
          break;
        case paymentOptions.DIRECT_BANK_DEPOSIT:
          try {
            const directBankDepositReferenceResponse = await makeDirectBankTransfer(
              values.recipientName.trim(),
              applicationUserEmail,
              values.isECertificateGifting ? values.email : applicationUserEmail,
              values.carbonQuantity,
              values.newsletter,
              taxCalculationHelperObject.applicationHostedRegion,
              taxCalculationHelperObject.businessLocatedCountry,
              taxCalculationHelperObject.vatRegistrationNumber,
              values.customerName.trim(),
              values.businessName.trim(),
              !values.isECertificateGifting && values.IsAutomaticallyLogCarbon ? true : false,
              instance
            );
            navigate('/checkout', {
              state: {
                carbonQuantity: values.carbonQuantity,
                price: directBankDepositReferenceResponse?.priceWithTax,
                tax: directBankDepositReferenceResponse?.tax,
                paymentReference: directBankDepositReferenceResponse?.paymentReference,
                isNotEnoughCarbon: directBankDepositReferenceResponse?.isNotEnoughCarbon,
                netPrice: directBankDepositReferenceResponse?.netPrice,
                bankAccountCreatedOn: directBankDepositReferenceResponse?.bankAccountCreatedOn,
                bankAccountIBAN: directBankDepositReferenceResponse?.bankAccountIBAN,
                bankAccountLocation: directBankDepositReferenceResponse?.bankAccountLocation,
                bankAccountName: directBankDepositReferenceResponse?.bankAccountName,
                bankAccountNumber: directBankDepositReferenceResponse?.bankAccountNumber,
                bankAccountSortCode: directBankDepositReferenceResponse?.bankAccountSortCode,
                bankAccountSwitfcode: directBankDepositReferenceResponse?.bankAccountSwitfcode,
                bankAddress: directBankDepositReferenceResponse?.bankAddress,
                bankName: directBankDepositReferenceResponse?.bankName
              }
            });
          } catch (error) {
            console.error('Error making the direct bank transfer:', error);
            setPaymentAPIError(true);
          }
        default:
          break;
      }
    } else {
      setShowLoginModal(true);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (priceCalcAPIError)
      toast.error(priceCalcAPIError, {
        onClose: () => setPriceCalcAPIError(null)
      });
    if (paymentAPIError)
      toast.error('Error in making the payment!', {
        onClose: () => setPaymentAPIError(null)
      });
  }, [priceCalcAPIError, paymentAPIError]);

  const checkoutClickHandler = async (dirty, validateForm, setTouched) => {
    await setTouched(
      {
        carbonQuantity: true,
        email: true,
        confirmEmail: true,
        recipientName: true,
        vatRegistrationNumber: true,
        businessLocatedCountry: true,
        iSBusinessOrIndividual: true,
        vatExemption: true,
        businessName: true,
        customerName: true,
        acceptTerms: true,
        paymentOption: true
      },
      true
    );

    const errors = await validateForm();
    if (
      Object.keys(errors)
        .filter((key) => key !== 'acceptTerms')
        .filter((key) => key !== 'paymentOption').length === 0
    ) {
      await setTouched(
        {
          acceptTerms: false,
          paymentOption: false
        },
        false
      );
      setShowLoginModal(true);
    }
  };
  const navigateToDashboard = () => {
    localStorage.removeItem('buyECertificateDetails');
    navigate('/dashboard');
  };

  const goToMyProfile = async () => {
    localStorage.removeItem('buyECertificateDetails');
    navigate('/myprofile');
  };

  const handleSignInSignUp = () => {
    navigate('/home');
  };

  const goToECertificateOrderHistory = () => {
    localStorage.removeItem('buyECertificateDetails');
    navigate('/orderHistory');
  };

  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  return (
    <div className="buycertificate-screens">
      <div className="row">
        <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 p-0 order-lg-1 order-md-2 order-2">
          <div className="background-img">
            <Button
              className="faq-btn"
              variant="primary"
              onClick={() => (isTabletOrMobile ? openOverlayMobile() : openOverlay())}
            >
              F <br />
              A <br />Q
            </Button>
            <div className="logo-wrapper">
              <Link to="/">
                <img src={WhiteLogo} alt="white-logo" className="logo-top" />
              </Link>
            </div>
            <div id="myNav" className="overlay" style={{ width: `${overlayWidth}%` }}>
              <div className="btn close-btn" onClick={closeOverlay}>
                <img src={Closebtn} alt="close-btn" />
              </div>
              <div className="logo-wrapper">
                <Link to="/">
                  <img src={WhiteLogo} alt="white-logo" className="logo-top" />
                </Link>
              </div>
              <div className="faq-modal-wrapper">
                <Modal.Header>
                  <Modal.Title className="faq-modal-title mt-5">Frequently Asked Questions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="faq-modal-body mt-1 mb-5 px-5">
                  <div className="wr-faq-modal-body">
                    <FaqAccordian overlayWidth={overlayWidth} />
                  </div>
                </Modal.Body>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 form-wrapper p-0 order-lg-2 order-md-1 order-1">
          <div className="home-nav payment-page-nav home-nav-dark">
            <div className="logo-wrapper">
              <Link to="/">
                <img src={BlackLogo} alt="white-logo" className="logo-top" />
              </Link>
            </div>
            <div className="home-nav-wrapper">
              <div className="web-buttons">
                <AuthenticatedTemplate>
                  <button onClick={navigateToDashboard} className="ms-3 btn-outline icon-left-btn">
                    Claimed Carbon (B2C)
                  </button>
                  <button onClick={goToECertificateOrderHistory} className="ms-3 btn-outline icon-left-btn">
                    Certificate Order History (B2B)
                  </button>
                  <button onClick={goToMyProfile} className="ms-3 btn-outline icon-left-btn">
                    <img src={userIcon} alt="profile icon" />
                    My Profile
                  </button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="dark" className="btn-outline icon-left-btn" onClick={() => handleSignInSignUp()}>
                    Sign In/Sign Up
                  </Button>
                </UnauthenticatedTemplate>
              </div>
              <div className="mobile-buttons">
                <AuthenticatedTemplate>
                  <Button onClick={navigateToDashboard} className="round-button ms-2">
                    <BiTransfer />
                  </Button>
                  <Button onClick={goToECertificateOrderHistory} className="round-button ms-2">
                    <FaHistory />
                  </Button>
                  <Button onClick={goToMyProfile} className="round-button ms-2">
                    <FaUser />
                  </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="dark" className="form-signin-btn" onClick={() => handleSignInSignUp()}>
                    Sign In/Sign Up
                  </Button>
                </UnauthenticatedTemplate>
              </div>
            </div>
          </div>

          <div className="buy-certificate-form-wrapper">
            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <div className="buy-certificate-form">
                  <div className="mb-4 pt-4">
                    <h1 className="form-name">
                      Purchase <span className="form-name-green">Carbon</span> Cancellation Certificate
                    </h1>
                  </div>
                  <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ setFieldValue, values, isSubmitting, validateForm, dirty, setTouched, submitForm, errors }) => (
                      <FormikForm>
                        <Form.Group controlId="carbonQuantity" className="mb-3">
                          <div className="form-label">
                            {minPurchasableCarbonAmountAPIInProgress ? (
                              <Form.Label>{`Carbon Quantity`}</Form.Label>
                            ) : (
                              <Form.Label>{`Carbon Quantity (minimum ${minPurchasableCarbonAmount} kg)`}</Form.Label>
                            )}
                          </div>
                          <Field
                            type="number"
                            name="carbonQuantity"
                            placeholder="Enter quantity in kg"
                            className={`form-control certificate-form-input ${
                              errors?.carbonQuantity ? 'invalid-input-values' : ''
                            }`}
                            onKeyDown={blockInvalidChar}
                            onChange={async (e, form) => {
                              if (e.target.value === '') {
                                setFieldValue('carbonQuantity', e.target.value);
                                setFieldValue('price', '');
                                return;
                              } else {
                                let enteredValue = e.target.value;
                                if (enteredValue === '.') {
                                  enteredValue = '0.';
                                }
                                // Use a regular expression to restrict input to numeric values with up to 3 decimal places
                                const regex = /^\d*\.?\d{0,3}$/;
                                if (regex.test(enteredValue)) {
                                  setFieldValue('carbonQuantity', enteredValue);
                                  try {
                                    const { price } = await calculateCarbonAmountPriceGivenWeight(enteredValue);
                                    setFieldValue('price', formatPrice(price, 2));
                                    saveBuyECertificateDetails({
                                      ...values,
                                      carbonQuantity: enteredValue,
                                      price: formatPrice(price, 2)
                                    });
                                  } catch (error) {
                                    if (error?.statusCode === 501 || error?.statusCode === 502) {
                                      setPriceCalcAPIError(
                                        "There's an issue with carbon cancellation certification purchasing. Please try again later."
                                      );
                                    } else {
                                      setPriceCalcAPIError(error?.message);
                                    }
                                    setFieldValue('carbonQuantity', '');
                                  }
                                }
                              }
                            }}
                          />
                          <ErrorMessage name="carbonQuantity" component="div" className="text-danger" />
                        </Form.Group>
                        <Form.Group controlId="price" className="mb-3">
                          <div className="form-label">
                            <Form.Label>Price</Form.Label>
                          </div>
                          <InputGroup className="price-input-label">
                            <InputGroup.Text id="basic-addon1">GBP</InputGroup.Text>
                            <Field
                              type="number"
                              name="price"
                              className="form-control certificate-form-input disabled-input"
                              readOnly
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="newsletter" as={Col}>
                          <Form.Check
                            type="checkbox"
                            label="Please email me with carbon news"
                            name="newsletter"
                            className="ps-4 email-checkbox"
                            onChange={(event) => {
                              saveBuyECertificateDetails({ ...values, newsletter: event.target.checked });
                              setFieldValue('newsletter', event.target.checked);
                            }}
                            checked={values.newsletter}
                          />
                        </Form.Group>
                        <Form.Group controlId="customerName" className="mb-3">
                          <div className="form-label">
                            <Form.Label>Contact Name</Form.Label>
                          </div>{' '}
                          <Field
                            type="text"
                            name="customerName"
                            placeholder="Contact name"
                            className={`form-control certificate-form-input ${
                              errors?.customerName ? 'invalid-input-values' : ''
                            }`}
                            onChange={(e) => {
                              saveBuyECertificateDetails({ ...values, customerName: e.target.value });
                              setFieldValue('customerName', e.target.value);
                            }}
                          />
                          <ErrorMessage name="customerName" component="div" className="text-danger" />
                        </Form.Group>
                        {/** new fields */}
                        <Form.Group controlId="businessLocatedCountry" className="mb-3">
                          <div className="form-label">
                            <Form.Label>Location</Form.Label>
                            <p>Where do you live or where is your business based?</p>
                          </div>
                          <Field
                            as="select"
                            name="businessLocatedCountry"
                            className={`form-select certificate-form-input ${
                              errors?.businessLocatedCountry ? 'invalid-input-values' : ''
                            }`}
                            onChange={(e) => {
                              saveBuyECertificateDetails({ ...values, businessLocatedCountry: e.target.value });
                              setFieldValue('businessLocatedCountry', e.target.value);
                            }}
                          >
                            <option value="">Select a country</option>
                            <option value={countryCodes.UNITED_KINGDOM}>United Kingdom</option>
                            <option value={countryCodes.AUSTRALIA}>Australia</option>
                            <option value={countryCodes.ELSEWHERE}>Elsewhere</option>
                          </Field>
                          <ErrorMessage name="businessLocatedCountry" component="div" className="text-danger" />
                        </Form.Group>
                        {values.businessLocatedCountry === countryCodes.UNITED_KINGDOM && (
                          <Form.Group controlId="iSBusinessOrIndividual" className="mb-3">
                            <div className="form-label">
                              <Form.Label>Are you a business or an individual?</Form.Label>
                            </div>
                            <Field
                              as="select"
                              name="iSBusinessOrIndividual"
                              className={`form-select certificate-form-input ${
                                errors?.iSBusinessOrIndividual ? 'invalid-input-values' : ''
                              }`}
                              onChange={(e) => {
                                saveBuyECertificateDetails({ ...values, iSBusinessOrIndividual: e.target.value });
                                setFieldValue('iSBusinessOrIndividual', e.target.value);
                              }}
                            >
                              <option value={iSBusinessOrIndividualOptions.None}>Select an Option</option>
                              <option value={iSBusinessOrIndividualOptions.BUSINESS}>Business</option>
                              <option value={iSBusinessOrIndividualOptions.INDIVIDUAL}>Individual</option>
                            </Field>
                            <ErrorMessage name="iSBusinessOrIndividual" component="div" className="text-danger" />
                          </Form.Group>
                        )}

                        {values.businessLocatedCountry === countryCodes.UNITED_KINGDOM &&
                          values.iSBusinessOrIndividual === 'business' && (
                            <Form.Group controlId="vatExemption" className="mb-3">
                              <div className="form-label">
                                <Form.Label className="form-label">For UK VAT purposes:</Form.Label>
                                <p>
                                  <MdInfoOutline />
                                  Please note - C2Zero does not supply tax advice. We will rely on your answer to these
                                  next questions to determine if you are required to pay VAT on this purchase. If you
                                  are unsure answer NO.
                                </p>
                              </div>

                              <div className="d-flex">
                                <div className="form-check radio-uk">
                                  <Field
                                    id="radio-yes"
                                    type="radio"
                                    name="vatExemption"
                                    value="yes"
                                    className="rad-input"
                                    onChange={(e) => {
                                      saveBuyECertificateDetails({ ...values, vatExemption: e.target.value });
                                      setFieldValue('vatExemption', e.target.value);
                                    }}
                                  />
                                  <label for="radio-yes" className="check-label">
                                    Yes, I am exempt or subject to a reverse charge
                                  </label>
                                </div>
                                <div className="form-check radio-uk">
                                  <Field
                                    id="radio-no"
                                    type="radio"
                                    name="vatExemption"
                                    value="no"
                                    className="rad-input"
                                    onChange={(e) => {
                                      saveBuyECertificateDetails({ ...values, vatExemption: e.target.value });
                                      setFieldValue('vatExemption', e.target.value);
                                    }}
                                  />
                                  <label for="radio-no" className="check-label">
                                    No, I am not exempt or subject to a reverse charge
                                  </label>
                                </div>
                              </div>
                              <ErrorMessage inline name="vatExemption" component="div" className="text-danger" />
                            </Form.Group>
                          )}
                        {values.businessLocatedCountry === countryCodes.UNITED_KINGDOM &&
                          values.iSBusinessOrIndividual === iSBusinessOrIndividualOptions.BUSINESS &&
                          values.vatExemption === 'yes' && (
                            <Form.Group controlId="businessName" className="mb-3">
                              <div className="form-label">
                                <Form.Label>Business Name</Form.Label>
                              </div>
                              <Field
                                type="text"
                                name="businessName"
                                placeholder="Enter business name"
                                className={`form-control certificate-form-input ${
                                  errors?.businessName ? 'invalid-input-values' : ''
                                }`}
                                onChange={(e) => {
                                  saveBuyECertificateDetails({ ...values, businessName: e.target.value });
                                  setFieldValue('businessName', e.target.value);
                                }}
                              />
                              <ErrorMessage name="businessName" component="div" className="text-danger" />
                            </Form.Group>
                          )}

                        {values.businessLocatedCountry === countryCodes.UNITED_KINGDOM &&
                          values.iSBusinessOrIndividual === iSBusinessOrIndividualOptions.BUSINESS &&
                          values.vatExemption === 'yes' && (
                            <Form.Group controlId="vatRegistrationNumber" className="mb-3">
                              <div className="form-label">
                                <Form.Label>VAT registration or exemption identifier</Form.Label>
                                <p>
                                  <MdInfoOutline />
                                  Required for those exempt or subject to a reverse charge. C2Zero will rely in this
                                  information and is not responsible for errors or omissions.
                                </p>
                              </div>
                              <Field
                                type="text"
                                name="vatRegistrationNumber"
                                placeholder="Enter VAT registration"
                                className={`form-control certificate-form-input ${
                                  errors?.vatRegistrationNumber ? 'invalid-input-values' : ''
                                }`}
                                onChange={(e) => {
                                  saveBuyECertificateDetails({ ...values, vatRegistrationNumber: e.target.value });
                                  setFieldValue('vatRegistrationNumber', e.target.value);
                                }}
                              />
                              <ErrorMessage name="vatRegistrationNumber" component="div" className="text-danger" />
                            </Form.Group>
                          )}

                        {/** end of new fields */}
                        <div className="gift-area-wrapper my-3">
                          <div className="form-label">
                            <label>Is this carbon for yourself or is it for someone else?</label>
                          </div>{' '}
                          <div className="gift-area-button-wrapper mt-1">
                            <Button
                              className={`btn gift-area-button me-3 ${values.isECertificateGifting ? 'active' : ''}`}
                              variant="light"
                              onClick={() => {
                                saveBuyECertificateDetails({ ...values, isECertificateGifting: true });
                                setFieldValue('isECertificateGifting', true);
                              }}
                            >
                              No, for someone else.
                            </Button>
                            <Button
                              className={`btn gift-area-button ${!values.isECertificateGifting ? 'active' : ''}`}
                              variant="light"
                              onClick={() => {
                                setFieldValue('isECertificateGifting', false);
                                saveBuyECertificateDetails({ ...values, isECertificateGifting: false });
                              }}
                            >
                              Yes, for me.
                            </Button>
                          </div>
                        </div>
                        {!values?.isECertificateGifting && (
                          <Form.Group controlId="IsAutomaticallyLogCarbon" as={Col}>
                            <Form.Check
                              type="checkbox"
                              label="Automatically log carbon to my account"
                              name="IsAutomaticallyLogCarbon"
                              className="ps-4 email-checkbox"
                              onChange={(event) => {
                                saveBuyECertificateDetails({
                                  ...values,
                                  IsAutomaticallyLogCarbon: event.target.checked
                                });
                                setFieldValue('IsAutomaticallyLogCarbon', event.target.checked);
                              }}
                              checked={values.IsAutomaticallyLogCarbon}
                            />
                          </Form.Group>
                        )}
                        <Form.Group controlId="recipientName" className="mb-3">
                          <div className="form-label">
                            <Form.Label>Business or Individual name</Form.Label>
                            <p>As it should appear on the certificate.</p>
                          </div>{' '}
                          <Field
                            type="text"
                            name="recipientName"
                            placeholder="Enter business or Individual name"
                            className={`form-control certificate-form-input ${
                              errors?.recipientName ? 'invalid-input-values' : ''
                            }`}
                            onChange={(e) => {
                              saveBuyECertificateDetails({ ...values, recipientName: e.target.value });
                              setFieldValue('recipientName', e.target.value);
                            }}
                          />
                          <ErrorMessage name="recipientName" component="div" className="text-danger" />
                        </Form.Group>
                        {values.isECertificateGifting && (
                          <>
                            <Form.Group controlId="email" className="mb-3">
                              <div className="form-label">
                                <Form.Label>Receiver Email</Form.Label>
                              </div>
                              <Field
                                type="email"
                                name="email"
                                placeholder="Enter receiver email"
                                className={`form-control certificate-form-input ${
                                  errors?.email ? 'invalid-input-values' : ''
                                }`}
                                onChange={(e) => {
                                  saveBuyECertificateDetails({ ...values, email: e.target.value });
                                  setFieldValue('email', e.target.value);
                                }}
                              />
                              <ErrorMessage name="email" component="div" className="text-danger" />
                            </Form.Group>

                            <Form.Group controlId="confirmEmail" className="mb-3">
                              <div className="form-label">
                                <Form.Label>Re-enter Receiver Email</Form.Label>
                              </div>
                              <Field
                                type="email"
                                name="confirmEmail"
                                placeholder="Re-enter receiver email"
                                className={`form-control certificate-form-input ${
                                  errors?.confirmEmail ? 'invalid-input-values' : ''
                                }`}
                                onChange={(e) => {
                                  saveBuyECertificateDetails({ ...values, confirmEmail: e.target.value });
                                  setFieldValue('confirmEmail', e.target.value);
                                }}
                              />
                              <ErrorMessage name="confirmEmail" component="div" className="text-danger" />
                            </Form.Group>
                          </>
                        )}
                        <div className="center-button-container my-5">
                          <Button
                            variant="primary"
                            className="submit-button"
                            onClick={() => checkoutClickHandler(dirty, validateForm, setTouched)}
                          >
                            Checkout
                          </Button>
                        </div>
                        <Modal
                          show={showLoginModal}
                          onHide={() => setShowLoginModal(false)}
                          className="buycertificate-screens"
                          backdrop="static"
                        >
                          <Modal.Header closeButton />
                          <Modal.Body className="sign-in-modal">
                            <div className="sign-in-modal-wrapper pb-4">
                              <div className="modal-text-wrapper">
                                <h1 className="modal-main-title pe-3">Proceeding to Checkout</h1>
                                <img src={ForwardArrow} alt="arrow-forward" className="arrow-forward" />
                              </div>
                              <div className="modal-text-box-wrapper my-3">
                                <p className="modal-text-box-para">
                                  <span className="green-text">Disclaimer: </span>
                                  All sales final. By completing this purchase, you agree to our terms and conditions.
                                  For assistance, contact customer support via{' '}
                                  <a href="mailto:support@c2zero.net">support@c2zero.net.</a>
                                  Thank you for choosing C2Zero.
                                </p>
                                <Form.Group controlId="acceptTerms" as={Col} className="mb-1">
                                  <Form.Check name="acceptTerms">
                                    <Form.Check.Input
                                      type="checkbox"
                                      onChange={(event) => {
                                        setFieldValue('acceptTerms', event.target.checked);
                                      }}
                                      checked={values.acceptTerms}
                                    />
                                    <Form.Check.Label className="terms-text">
                                      Accept{' '}
                                      <a
                                        href="https://www.c2zero.net/terms-ukas"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="terms-link"
                                      >
                                        <span className="link-span">Terms of Service</span>
                                      </a>{' '}
                                      &{' '}
                                      <a
                                        href="https://www.c2zero.net/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="terms-link"
                                      >
                                        <span className="link-span">Privacy Policy</span>
                                      </a>
                                    </Form.Check.Label>
                                  </Form.Check>
                                  <ErrorMessage name="acceptTerms" component="div" className="text-danger" />
                                </Form.Group>
                              </div>
                              {instance.getActiveAccount() ? (
                                <>
                                  <div className="payment-options-section mt-3">
                                    <h3>Select a payment option to continue</h3>
                                    <div className="payment-options">
                                      <div className="form-check">
                                        <Field
                                          id="radio-credit-card-option"
                                          type="radio"
                                          name="paymentOption"
                                          value={paymentOptions.STRIPE}
                                          className="rad-input"
                                        />
                                        <label htmlFor="radio-credit-card-option" className="check-label">
                                          <div className="card-logo">
                                            <img src={VisaCard} alt="arrow-forward" className="arrow-forward" />
                                            <img src={MasterCard} alt="arrow-forward" className="arrow-forward" />
                                            <img src={AmexCard} alt="arrow-forward" className="arrow-forward" />
                                          </div>
                                          Debit/Credit Card
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <Field
                                          id="radio-bank-deposit-option"
                                          type="radio"
                                          name="paymentOption"
                                          value={paymentOptions.DIRECT_BANK_DEPOSIT}
                                          className="rad-input"
                                        />
                                        <label htmlFor="radio-bank-deposit-option" className="check-label">
                                          <div>
                                            <BiTransferAlt />
                                          </div>
                                          Direct Bank Transfer
                                        </label>
                                      </div>
                                    </div>
                                    <ErrorMessage name="paymentOption" component="div" className="text-danger" />
                                  </div>
                                  <Button
                                    variant="primary"
                                    className="submit-button mt-3"
                                    disabled={isSubmitting || values?.acceptTerms === false || !values?.paymentOption}
                                    onClick={() => submitForm()}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-wrapper">
                                        <Spinner animation="border" size="sm" variant="light" className="me-2" />
                                      </span>
                                    )}
                                    Continue
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="primary"
                                    onClick={() => {
                                      saveBuyECertificateDetails(values);
                                      handleSignIn();
                                    }}
                                    className="modal-btn btn-black mt-3 mb-2"
                                    disabled={values?.acceptTerms === false}
                                  >
                                    Sign in
                                  </Button>

                                  <label className="sign-up-text">
                                    New User?
                                    <Button
                                      variant="link"
                                      className="sign-up"
                                      onClick={() => handleJoinc2zero()}
                                      disabled={values?.acceptTerms === false}
                                    >
                                      Create Account
                                    </Button>
                                  </label>
                                  {/* <div className="wr-vertical-lines">
                                <hr className="vertical-lines" />
                                <label className="px-3">or</label>
                                <hr className="vertical-lines" />
                              </div> */}
                                  {/* <Button
                                variant="link"
                                onClick={() => handleContineAsGuest(values)}
                                className="guest-btn"
                                disabled={values?.acceptTerms === false}
                              >
                                Continue as a Guest
                              </Button> */}
                                  {/* <div className="modal-footer">
                                    <p className="footer-text">
                                      <MdInfoOutline />
                                      You will be able to sign in or create account after you check out
                                    </p>
                                  </div> */}
                                </>
                              )}
                            </div>
                          </Modal.Body>
                        </Modal>
                      </FormikForm>
                    )}
                  </Formik>
                  <p className="text-center copyright-text">Copyright © {new Date().getFullYear()} C2Zero.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCarbonCertificateForm;
