import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdOutlineContentCopy } from 'react-icons/md';

const BankDetailsInfo = ({
  showMoreEnable,
  bankAccountIBAN,
  bankAccountLocation,
  bankAccountName,
  bankAccountNumber,
  bankAccountSortCode,
  bankAccountSwitfcode,
  bankAddress,
  bankName,
  amount
}) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <Card className="bank-details-card">
      <Card.Body>
        {/* <Card.Title>Bank Account Information</Card.Title> */}

        <Card.Text>
          <InfoRow label="Account Name" value={bankAccountName} copyable />
          <InfoRow label="Account Number" value={bankAccountNumber} copyable />
          <InfoRow label="Sort Code" value={bankAccountSortCode} copyable />
          {showMoreEnable && <InfoRow label="Amount" value={amount} copyable />}
          {showMoreEnable && showMore && (
            <>
              <InfoRow label="SWIFT Code" value={bankAccountSwitfcode} copyable />
              <InfoRow label="Account Location" value={bankAccountLocation} />
              <InfoRow label="IBAN" value={bankAccountIBAN} copyable />
              <InfoRow label="Bank Name" value={bankName} />
              <InfoRow label="Bank Address" value={bankAddress} />
            </>
          )}
          {!showMoreEnable && (
            <>
              <InfoRow label="SWIFT Code" value={bankAccountSwitfcode} copyable />
              <InfoRow label="Account Location" value={bankAccountLocation} />
              <InfoRow label="IBAN" value={bankAccountIBAN} copyable />
              <InfoRow label="Bank Name" value={bankName} />
              <InfoRow label="Bank Address" value={bankAddress} />
            </>
          )}

          {showMoreEnable && (
            <Button variant="link" onClick={handleShowMore} className="mt-2 mb-3">
              {showMore ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

function InfoRow({ label, value, copyable = false }) {
  return (
    <div className="mb-2 d-flex align-items-center">
      <p className="label">{label}: </p>
      <p className="data">{value}</p>
      {copyable && (
        <CopyToClipboard text={value}>
          <MdOutlineContentCopy />
        </CopyToClipboard>
      )}
    </div>
  );
}

export default BankDetailsInfo;
