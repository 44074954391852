import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import { CheckoutForm } from './CheckoutForm';
import { useLocation, useNavigate } from 'react-router';
import BackButton from '../../assets/images/arrow_back.svg';
import BlackLogo from '../../assets/images/logo_black.svg';
import userIcon from '../../assets/images/user-icon.svg';
import WhiteLogo from '../../assets/images/C2Zero-white-Logo.png';
import Phoneimage from '../../assets/images/Phone-img.png';
import StripeLogo from '../../assets/images/Stripe.svg';
import MobileScreenImg from '../../assets/images/mobile-display-img.png';
import { convertUnits } from '../../common/formatter';
import PageLoader from '../../components/PageLoader';
import { formatPrice } from '../../util/helpers';
import BankDetailsInfo from './BankDetailsInfo';
import { Button } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { BiTransfer } from 'react-icons/bi';
import { FaHistory, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdOutlineInfo } from 'react-icons/md';

const PaymentPage = (props) => {
  const { stripePromise } = props;
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate('/buyCertificate');
  };

  const goToMyProfile = async () => {
    localStorage.removeItem('buyECertificateDetails');
    navigate('/myprofile');
  };

  const handleSignInSignUp = () => {
    navigate('/home');
  };

  const goToECertificateOrderHistory = () => {
    localStorage.removeItem('buyECertificateDetails');
    navigate('/orderHistory');
  };

  const navigateToDashboard = () => {
    localStorage.removeItem('buyECertificateDetails');
    navigate('/dashboard');
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!state?.price || !state?.carbonQuantity) {
      navigate('/buyCertificate');
    }
    // eslint-disable-next-line
  }, [state]);

  if (!state?.price || !state?.carbonQuantity) {
    return <PageLoader />;
  }

  return (
    <div className="buycertificate-screens payment-page">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 order-lg-1 order-md-2 order-2">
          <div className="payment-background">
            <div className="cart-product-data">
              <div>
                <div className="payment-header">
                  <button className="back-btn" onClick={() => handleBackButton()}>
                    <img src={BackButton} alt="backbutton" />
                  </button>
                  <img src={WhiteLogo} alt="white-logo" className="white-logo" />
                </div>
                <div className="payment-detail-wrapper">
                  <div className="payment-details-text-wrapper">
                    <h4 className="payment-details-title">Buy Carbon Cancellation Certificate</h4>
                    <h1 className="payment-details-price">{`£${Number(
                      formatPrice(
                        Number(state?.paymentReference ? state?.price : state?.priceWithTaxAndProcessingFee),
                        2
                      )
                    ).toLocaleString()}`}</h1>
                  </div>

                  <div className="payment-detail-box-wrapper">
                    <div className="payment-detail-box">
                      <div className="image-wrapper">
                        <img src={Phoneimage} alt="mobile-img" className="mobile-img" />
                      </div>
                      <div className="text-wrapper pe-3 ps-3">
                        <h1 className="main-title">C2Zero Carbon Cancellation Certificate</h1>
                        <h3 className="sub-title">{convertUnits(Number(state?.carbonQuantity), 'kg')}</h3>
                      </div>
                    </div>
                    <div className="price-tag-wrapper">
                      <p className="price-tag">
                        £
                        <span>{`${Number(
                          formatPrice(
                            Number(state?.paymentReference ? state?.price : state?.priceWithTaxAndProcessingFee),
                            2
                          )
                        ).toLocaleString()}`}</span>
                      </p>
                    </div>
                  </div>

                  <div className="total-detail-wrapper">
                    <div className="total-detail other-details">
                      <div className="total-title">
                        <h1 className="main-title">Net Price</h1>
                      </div>
                      <div className="price-tag-wrapper">
                        <p className="price-tag">
                          £<span>{`${Number(state.netPrice).toLocaleString()}`}</span>
                        </p>
                      </div>
                    </div>
                    {state.tax > 0 && (
                      <div className="total-detail other-details">
                        <div className="total-title">
                          <h1 className="main-title">Tax</h1>
                        </div>
                        <div className="price-tag-wrapper">
                          <p className="price-tag">
                            £<span>{`${Number(state.tax).toLocaleString()}`}</span>
                          </p>
                        </div>
                      </div>
                    )}
                    {state.processingFee > 0 && (
                      <div className="total-detail other-details">
                        <div className="total-title">
                          <h1 className="main-title">
                            Card Processing Fee{' '}
                            <span>{`(${(
                              state?.processingFeePercentage * state?.defaultNumericalValueProcessingFee
                            ).toFixed(2)}%)`}</span>
                          </h1>
                        </div>
                        <div className="price-tag-wrapper">
                          <p className="price-tag">
                            £<span>{`${Number(state.processingFee).toLocaleString()}`}</span>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="total-detail">
                      <div className="total-title">
                        <h1 className="main-title">Total</h1>
                      </div>
                      <div className="price-tag-wrapper">
                        <p className="price-tag">
                          £
                          <span>{`${Number(
                            formatPrice(
                              Number(state?.paymentReference ? state?.price : state?.priceWithTaxAndProcessingFee),
                              2
                            )
                          ).toLocaleString()}`}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-payment-detail-wrapper">
                  <div className="image-wrapper">
                    <img src={MobileScreenImg} alt="mobile-screen-img" className="mobile-screen-image" />
                  </div>
                  <div className="text-wrapper text-center pe-3 ps-3 mt-5">
                    <h1 className="main-title mt-1">C2Zero Carbon Cancellation Certificate</h1>
                    <h3 className="sub-title mt-1">{convertUnits(Number(state?.carbonQuantity), 'kg')}</h3>
                    <p className="price-tag mt-1">
                      £
                      <span>{`${Number(
                        formatPrice(
                          Number(state?.paymentReference ? state?.price : state?.priceWithTaxAndProcessingFee),
                          2
                        )
                      ).toLocaleString()}`}</span>
                    </p>
                  </div>
                  <div className="total-detail-wrapper">
                    <div className="total-detail other-details">
                      <div className="total-title">
                        <h1 className="main-title">Net Price</h1>
                      </div>
                      <div className="price-tag-wrapper">
                        <p className="price-tag">
                          £<span>{`${Number(state.netPrice).toLocaleString()}`}</span>
                        </p>
                      </div>
                    </div>
                    {state.tax > 0 && (
                      <div className="total-detail other-details">
                        <div className="total-title">
                          <h1 className="main-title">Tax</h1>
                        </div>
                        <div className="price-tag-wrapper">
                          <p className="price-tag">
                            £<span>{`${Number(state.tax).toLocaleString()}`}</span>
                          </p>
                        </div>
                      </div>
                    )}
                    {state.processingFee > 0 && (
                      <div className="total-detail other-details">
                        <div className="total-title">
                          <h1 className="main-title">
                            Card Processing Fee{' '}
                            <span>{`(${(
                              state?.processingFeePercentage * state?.defaultNumericalValueProcessingFee
                            ).toFixed(2)}%)`}</span>
                          </h1>
                        </div>
                        <div className="price-tag-wrapper">
                          <p className="price-tag">
                            £<span>{`${Number(state.processingFee).toLocaleString()}`}</span>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="total-detail">
                      <div className="total-title">
                        <h1 className="main-title">Total</h1>
                      </div>
                      <div className="price-tag-wrapper">
                        <p className="price-tag">
                          £
                          <span>{`${Number(
                            formatPrice(
                              Number(state?.paymentReference ? state?.price : state?.priceWithTaxAndProcessingFee),
                              2
                            )
                          ).toLocaleString()}`}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {state?.clientSecret && state?.paymentIntentId && (
                <div className="payment-footer">
                  <p className="logo-text pe-3">Powered by</p>
                  <img src={StripeLogo} alt="stripe-logo" className="stripe-logo" />
                  <div className="vl mx-3"></div>
                  <a
                    href="https://www.c2zero.net/terms-ukas"
                    target="_blank"
                    rel="noreferrer"
                    className="payment-footer-links pe-3"
                  >
                    Terms
                  </a>
                  <a
                    href="https://www.c2zero.net/privacy"
                    target="_blank"
                    rel="noreferrer"
                    className="payment-footer-links"
                  >
                    Privacy
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 form-wrapper p-0 order-lg-2 order-md-1 order-1">
          <div className="home-nav payment-page-nav home-nav-dark">
            <div className="logo-wrapper">
              <Link to="/">
                <img src={BlackLogo} alt="white-logo" className="logo-top" />
              </Link>
            </div>
            <div className="home-nav-wrapper">
              <div className="web-buttons">
                <AuthenticatedTemplate>
                  <button onClick={navigateToDashboard} className="ms-3 btn-outline icon-left-btn">
                    Claimed Carbon (B2C)
                  </button>
                  <button onClick={goToECertificateOrderHistory} className="ms-3 btn-outline icon-left-btn">
                    Certificate Order History (B2B)
                  </button>
                  <button onClick={goToMyProfile} className="ms-3 btn-outline icon-left-btn">
                    <img src={userIcon} alt="profile icon" />
                    My Profile
                  </button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="dark" className="btn-outline icon-left-btn" onClick={() => handleSignInSignUp()}>
                    Sign In/Sign Up
                  </Button>
                </UnauthenticatedTemplate>
              </div>
              <div className="mobile-buttons">
                <AuthenticatedTemplate>
                  <Button onClick={navigateToDashboard} className="round-button ms-2">
                    <BiTransfer />
                  </Button>
                  <Button onClick={goToECertificateOrderHistory} className="round-button ms-2">
                    <FaHistory />
                  </Button>
                  <Button onClick={goToMyProfile} className="round-button ms-2">
                    <FaUser />
                  </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="dark" className="form-signin-btn" onClick={() => handleSignInSignUp()}>
                    Sign In/Sign Up
                  </Button>
                </UnauthenticatedTemplate>
              </div>
            </div>
          </div>
          {state?.clientSecret && state?.paymentIntentId ? (
            <div className="stripe-form-wrapper mt-5 pb-5">
              <div className="stripe-form-data">
                <h1 className="stripe-form-title">Enter Payment Details</h1>
                {state?.clientSecret && stripePromise && (
                  <Elements stripe={stripePromise} options={{ clientSecret: state?.clientSecret }}>
                    <CheckoutForm clientSecret={state?.clientSecret} paymentIntentId={state?.paymentIntentId} />
                  </Elements>
                )}
                {state?.isNotEnoughCarbon && (
                  <p className="payment-page-info">
                    <MdOutlineInfo />
                    Carbon prices are volatile. In the event that there is a large market move, we may not be able to
                    complete your order at current price levels. If this is the case, we will completely refund any
                    payment you have made or give you the option to change the size of your order.
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="buy-certificate-form-wrapper pt-5 pb-5">
              <div className="buy-certificate-form">
                <div className="row d-flex justify-content-center">
                  <div className="col-12 bank-detail-content-wrapper">
                    <strong>
                      <p>Please transfer funds to:</p>
                    </strong>
                    <BankDetailsInfo
                      showMoreEnable={true}
                      bankAccountIBAN={state?.bankAccountIBAN}
                      bankAccountLocation={state?.bankAccountLocation}
                      bankAccountName={state?.bankAccountName}
                      bankAccountNumber={state?.bankAccountNumber}
                      bankAccountSortCode={state?.bankAccountSortCode}
                      bankAccountSwitfcode={state?.bankAccountSwitfcode}
                      bankAddress={state?.bankAddress}
                      bankName={state?.bankName}
                      amount={`£ ${Number(formatPrice(Number(state?.price), 2)).toLocaleString()}`}
                    />
                    <div className="payment-reference mb-0 pb-0">
                      <h3>Payment Reference:</h3>
                      <p>{state?.paymentReference}</p>
                    </div>
                    <div className="small">(Please include with your transfer)</div>
                    <strong>
                      {' '}
                      <p className="payment-page-info">You will receive an email with these details.</p>
                    </strong>
                    <div className="continue-button d-flex">
                      <Button
                        onClick={() => {
                          navigate('/thankyou', { state: { paymentReference: state?.paymentReference } });
                        }}
                        className="btn-default mt-5"
                      >
                        Continue to purchase certificate
                      </Button>
                    </div>
                  </div>

                  {state?.isNotEnoughCarbon && (
                    <div className="col-12">
                      <p className="payment-page-info mt-5">
                        <MdOutlineInfo />
                        Carbon prices are volatile. In the event that there is a large market move, we may not be able
                        to complete your order at current price levels. If this is the case, we will completely refund
                        any payment you have made or give you the option to change the size of your order.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
