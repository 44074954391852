import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const FaqAccordian = ({ overlayWidth }) => {
  const [activeKey, setActiveKey] = useState(null);
  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey);
  };
  useEffect(() => {
    setActiveKey(null);
  }, [overlayWidth]);

  return (
    <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
      <Accordion.Item eventKey="0" className="faq-accordion-item mb-3">
        <Accordion.Header className="faq-accordion-header">How does C2Zero work?</Accordion.Header>
        <Accordion.Body>
          C2Zero participates in the international carbon markets to buy carbon allowances. These are logged into our
          digital registry. We then embed that carbon into everyday goods, services and activities or operational
          footprint of our customers, using proprietary serialised microcredits which are locked in the C2Zero vault
          “CEDO”. These C2Zero microcredits in CEDO reference the amount of C02 which is locked away and ultimately
          cancelled for our customers. This enables them to track the C02 emissions that they have prevented from
          entering the earths atmosphere, forever.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="faq-accordion-item mb-3">
        <Accordion.Header className="faq-accordion-header">Our provenance</Accordion.Header>
        <Accordion.Body>
          C2Zero promises that we will never ever use an emissions allowance for polluting. That is the core of our
          business and without it, we are nothing. The C2Zero brandmark is a mark of trust. Every time you see it, you
          can be sure that what is behind it is real. Obviously, we can say that, but without proof then how do you know
          it is true? That is why we have gone to considerable lengths and expense to build an ecosystem which provides
          full provenance in a way which is robust and transparent. Every stakeholder can track and monitor their
          activity. Every brand can see their impact and every user can see their contribution. Every emissions
          allowance held by C2Zero and the underlying C02 it is mapped to is securely held and fully traceable. All this
          is can also be externally verified by an independent auditor. The core component to C2Zero are the emissions
          allowances we purchase, cut into bite size pieces and attach to goods and services. These emissions allowances
          (not offsets linked to things like trees or other activities) are held in our digital vault CEDO never to
          pollute. Big polluters (unlike C2Zero) must surrender these allowances when they pollute, otherwise they get
          into trouble. We take allowances away from big polluters and lock them away in our (digital) vault CEDO
          forever, or we surrender them – just like the polluters do – but with one big difference. We do not pollute.
          One emissions allowance surrendered by a big polluter = 1 tonne of CO2 (or equivalent) released into the
          earth’s atmosphere. One emissions allowance held or surrendered by C2Zero = no CO2 released. Our ledgers and
          our vault are all transparent. Provenance is the heart and soul of C2Zero. Every transaction - from the 450
          grams of CO2 attached to your coffee to the one tonne in your T-shirt or sunglasses - is recorded in our
          ledger. Every business, brand or individual can monitor and track the carbon linked to their goods services,
          activities and more. Every user can monitor and track this to see the good they are doing, locking away
          forever the permits that big polluters would otherwise use to pollute. No polluter will get their hands on an
          emissions allowance that is held by C2Zero. Never ever. That’s our promise.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="faq-accordion-item mb-3">
        <Accordion.Header className="faq-accordion-header">What is a carbon footprint?</Accordion.Header>
        <Accordion.Body>
          The discussion about climate change and around carbon can be confusing. To start with, greenhouse gases,
          carbon and carbon dioxide are all different things. Then we have carbon footprints, which is a way of talking
          about a quantity of gas, and we measure that footprint in tonnes. A carbon footprint is the total amount of
          greenhouse gases (including carbon dioxide and methane) that are generated by an individual, event,
          organisation, service or product, expressed as a carbon dioxide equivalent.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="faq-accordion-item mb-3">
        <Accordion.Header className="faq-accordion-header">Offsetting</Accordion.Header>
        <Accordion.Body>
          Offsetting refers to actions taken to counterbalance greenhouse gas emissions, typically measured in CO2
          equivalents, with the goal of neutralizing the impact of emissions-producing activities. This concept is
          commonly applied in scenarios such as airline travel, where passengers can opt to offset the carbon footprint
          of their flights. Although the idea seems straightforward—counteracting CO2 emissions with an equal amount of
          CO2 reduction—the practical implementation is complex. Most commonly, offsetting is associated with planting
          or conserving trees, which naturally absorb CO2. However, the effectiveness and genuineness of such offsets
          are debated, especially when it involves mature trees that have been absorbing CO2 long before being
          designated as offsets. The transactional aspect of offsetting, involving money paid by individuals to
          theoretically support CO2-reducing projects, adds another layer of complexity, raising questions about the
          real impact of these actions in reducing net emissions.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="faq-accordion-item mb-3">
        <Accordion.Header className="faq-accordion-header">What does 1 tonne of CO2 look like?</Accordion.Header>
        <Accordion.Body>
          People are not good at responding to invisible threats. We react quickly if something looks menacing, anything
          invisible tends to be ignored. Believe us 1 tonne of CO2 (carbon dioxide) is menacing and should not be
          ignored.At low concentrations CO2 is invisible, colourless and odourless; however, at sufficiently-high
          concentrations, it has a sharp, acidic odour. This however, does not prevent it, like the air, from having a
          mass: 1,964 g/litre or 1.964 kg/m3 and is about 1.53 times the density of air. To help you visualise 1 Tonne
          of CO2 it is equivalent to the average emission of a return-flight economy from Paris to New York.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" className="faq-accordion-item mb-3">
        <Accordion.Header className="faq-accordion-header">
          How do we store Emission Allowances and how do you know they are locked away forever?
        </Accordion.Header>
        <Accordion.Body>
          C2Zero logs our carbon credit allowances into CEDO (in the blockchain), so they are accounted for and will be
          locked away forever. You can’t rob this bank. It’s our forever guarantee that the good you do with C2Zero in
          this life will never be undone, ever. And anyone can view the amount that is locked away, we are fully
          transparent. Our carbon vault CEDO is independently audited (by a reputable external organisation) to provide
          another level of legitimacy, transparency and provenance.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FaqAccordian;
