import React, { useContext } from 'react';
import '../styles/style.scss';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  handleSignup,
  handleLogin,
  handleLogout,
  handleLoginFromClaim,
  handleSignupFromClaim
} from '../services/authService';

import iconArrowForward from '../assets/images/arrow-forward-icon.svg';
import { Link } from 'react-router-dom';
import AppContext from '../contexts/AppContext';
import { appProperties } from '../util/appProperties';

export const SigninSignup = (props) => {
  const instance = props.instance;
  const applicationContext = useContext(AppContext);
  const { isECertificateFeatureAllowed } = applicationContext;
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="signin">
      <div className="signin-content">
        {!props.scanId && (
          <p className="keep-track-text">
            {!isECertificateFeatureAllowed && "Keep track of how you're helping the world go beyond neutral"}
          </p>
        )}
        <div className="button-wrapper">
          <div className="sign-button-with-label">
            <span>New User</span>
            <button
              className="btn-default"
              onClick={() =>
                props.scanId
                  ? handleSignupFromClaim(instance, isAuthenticated, inProgress)
                  : handleSignup(instance, isAuthenticated, inProgress)
              }
            >
              Join C2Zero
            </button>
          </div>
          <div className="sign-button-with-label">
            <span>Existing User</span>
            <button
              className="btn-outline"
              onClick={() =>
                props.scanId ? handleLoginFromClaim(instance, isAuthenticated, inProgress) : handleLogin(instance)
              }
            >
              Sign In
            </button>
          </div>
        </div>
        {/* <Link to="/orderHistory" className="guest-link">
          Continue as a Guest
        </Link> */}
        <div className="row">
          <p className="learn-more-text">
            <span className="me-1">Privacy policy</span>
            <a target="_blank" href={`${appProperties.appHost}/Privacy.html`}>
              Privacy
            </a>
            <Link to="/privacy" target="_blank" rel="noopener noreferrer" />
            <br />
            <span className="me-1"> Terms & Conditions </span>
            <a target="_blank" href="https://c2zero.net/terms">
              T&C
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export const SignOutButton = (props) => {
  const { instance } = useMsal();

  return (
    <React.Fragment>
      {props.fullbutton ? (
        <button className="btn-full" onClick={() => handleLogout(instance)}>
          <span>Sign Out</span> <img src={iconArrowForward} alt="arrow icon" />
        </button>
      ) : (
        <button className="btn-outline" onClick={() => handleLogout(instance)}>
          Sign out
        </button>
      )}
    </React.Fragment>
  );
};
