import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import WhiteLogo from '../assets/images/logo_white.svg';
import Closebtn from '../assets/images/Close.png';
import FaqAccordian from '../features/BuyCertificates/FaqAccordian';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router';
import { getEcertificateOrderSummary } from '../services/eCertificateService';
import PageLoader from './PageLoader';
import { toast } from 'react-toastify';
import { convertUnits } from '../common/formatter';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Link } from 'react-router-dom';
import BlackLogo from '../assets/images/logo_black.svg';
import userIcon from '../assets/images/user-icon.svg';
import { FaUser, FaHistory } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';
import { createOrderWithDirectPaymentRef } from '../services/stripeService';

export const ThankyouPage = () => {
  const { instance } = useMsal();
  const [overlayWidth, setOverlayWidth] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [getPurchaseSummaryInProgress, setGetPurchaseSummaryInProgress] = useState(true);
  const [purchaseSummary, setPurchaseSummary] = useState(null);
  const [getOrderSummaryAPIError, setGetOrderSummaryAPIError] = useState(false);
  const navigate = useNavigate();
  const {
    state: { clientSecret, paymentIntentId, paymentReference }
  } = useLocation();

  useEffect(() => {
    const delay = 10000; // Adjust the delay time in milliseconds (e.g., 3000 for 3 seconds)
    let timerId;

    if (paymentReference) {
      const createOrderWithDirectPaymentReference = async () => {
        try {
          const response = await createOrderWithDirectPaymentRef(paymentReference, instance);
          if (response) toast.success('Order successfully created.');
        } catch (error) {
          console.log('ERRGetEcertificateOrderHistory: ', error);
          toast.error(error?.message);
        }
      };
      setGetPurchaseSummaryInProgress(false);
      createOrderWithDirectPaymentReference();
    } else if (clientSecret && paymentIntentId) {
      timerId = setTimeout(() => {
        const getPurchaseSummary = async () => {
          try {
            const response = await getEcertificateOrderSummary(paymentIntentId, instance);
            if (response) {
              setPurchaseSummary(response);
            }
          } catch (error) {
            console.log('ERRGetEcertificateOrderHistory: ', error);
            // show error message notification
            setGetOrderSummaryAPIError(true);
          } finally {
            setGetPurchaseSummaryInProgress(false);
          }
        };
        getPurchaseSummary();
      }, delay);
    } else if ((!clientSecret && !paymentIntentId) || !paymentReference) {
      navigate('/buyCertificate');
    }
    // Clear the timeout in case the component unmounts or useEffect re-runs
    return () => {
      if (timerId) clearTimeout(timerId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getOrderSummaryAPIError)
      toast.error('Error in receiving order summary!', {
        onClose: () => setGetOrderSummaryAPIError(false)
      });
  }, [getOrderSummaryAPIError]);

  const handleSignIn = () => {
    handleLogin(instance);
  };

  const openOverlay = () => {
    setOverlayWidth(100);
  };
  const openOverlayMobile = () => {
    setOverlayWidth(100);
  };
  const closeOverlay = () => {
    setOverlayWidth(0);
  };
  const lockAwayMoreCarbonHandler = () => {
    navigate('/buyCertificate');
  };

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  const goToMyProfile = async () => {
    navigate('/myprofile');
  };

  const goToECertificateOrderHistory = () => {
    navigate('/orderHistory');
  };

  useEffect(() => {
    localStorage.removeItem('buyECertificateDetails');
    // localStorage.removeItem('guestUserEmail');
  }, []);

  if (getPurchaseSummaryInProgress) {
    return <PageLoader />;
  }

  return (
    <div className="buycertificate-screens">
      <div className="row">
        <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 order-lg-1 order-md-2 order-2 p-0">
          <div className="background-img">
            <Button
              className="faq-btn"
              variant="primary"
              onClick={() => (isTabletOrMobile ? openOverlayMobile() : openOverlay())}
            >
              F <br />
              A <br />Q
            </Button>
            <div className="logo-wrapper">
              <Link to="/">
                <img src={WhiteLogo} alt="white-logo" className="logo-top" />
              </Link>
            </div>

            <h1 className="form-name-green mt-4 mb-5 text-center">
              Lock Away <span className="form-name-green-mobile">Carbon</span>{' '}
            </h1>
            <div id="myNav" className="overlay" style={{ width: `${overlayWidth}%` }}>
              <div className="btn close-btn" onClick={closeOverlay}>
                <img src={Closebtn} alt="close-btn" />
              </div>
              <div className="logo-wrapper">
                <img src={WhiteLogo} alt="white-logo" className="logo-top" />
              </div>
              <div className="faq-modal-wrapper">
                <Modal.Header>
                  <Modal.Title className="faq-modal-title mt-5">Frequently Asked Questions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="faq-modal-body mt-1 mb-5 px-5">
                  <div className="wr-faq-modal-body">
                    <FaqAccordian overlayWidth={overlayWidth} />
                  </div>
                </Modal.Body>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 order-lg-2 order-md-1 order-1 p-0">
          <div className="home-nav payment-page-nav home-nav-dark">
            <div className="logo-wrapper">
              <Link to="/">
                <img src={BlackLogo} alt="white-logo" className="logo-top" />
              </Link>
            </div>
            <div className="home-nav-wrapper ">
              <div className="web-buttons">
                <AuthenticatedTemplate>
                  <Button onClick={navigateToDashboard} className="ms-3 btn-outline icon-left-btn">
                    Claimed Carbon (B2C)
                  </Button>
                  <Button onClick={goToECertificateOrderHistory} className="ms-3 btn-outline icon-left-btn">
                    Certificate Order History (B2B)
                  </Button>
                  <Button onClick={goToMyProfile} className="ms-3 btn-outline icon-left-btn">
                    <img src={userIcon} alt="profile icon" />
                    My Profile
                  </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="dark" className="btn-outline icon-left-btn" onClick={() => handleSignInSignUp()}>
                    Sign In/Sign Up
                  </Button>
                </UnauthenticatedTemplate>
              </div>
              <div className="mobile-buttons">
                <AuthenticatedTemplate>
                  <Button onClick={navigateToDashboard} className="round-button ms-2">
                    <BiTransfer />
                  </Button>
                  <Button onClick={goToECertificateOrderHistory} className="round-button ms-2">
                    <FaHistory />
                  </Button>
                  <Button onClick={goToMyProfile} className="round-button ms-2">
                    <FaUser />
                  </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="dark" className="form-signin-btn" onClick={() => handleSignInSignUp()}>
                    Sign In/Sign Up
                  </Button>
                </UnauthenticatedTemplate>
              </div>
            </div>
          </div>

          <div className="form-wrapper">
            <div className="buy-certificate-form-wrapper">
              <div className="buy-certificate-form">
                {!getOrderSummaryAPIError && (
                  <div className="purchase-text-wrapper mt-4">
                    <h1 className="purchase-text-title">
                      Thank you for your <br /> Carbon Cancellation Certificate purchase!
                    </h1>
                    <p className="purchase-text mt-4">Your Impact: Preventing carbon emissions.</p>
                    {purchaseSummary ? (
                      <p className="purchase-text-small mt-1">
                        We are currently preparing your carbon cancellation certificate and will notify you through
                        email as soon as it is ready.{' '}
                        {purchaseSummary?.isNotEnoughCarbon && <span>This may take several days.</span>}
                      </p>
                    ) : (
                      <p className="purchase-text-small mt-1">
                        Your Carbon Cancellation Certificate order will be processed once the bank transfer has been
                        successfully completed.
                      </p>
                    )}
                    <div className="my-3 mt-5">
                      <hr className="dash-lines" />
                    </div>
                    {purchaseSummary && (
                      <div className="wr-purchase-summery">
                        <div className="wr-purchase-summery-title">
                          <h1 className="purchase-summery-title py-3">Purchase Summary</h1>
                        </div>
                        <div className="d-flex wr-main-details-list">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th scope="row" className="black-text">
                                  Date
                                </th>
                                <td className="grey-text">
                                  {new Date(purchaseSummary.purchaseDate).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="black-text">
                                  Carbon Quantity
                                </th>
                                <td className="grey-text">
                                  {convertUnits(purchaseSummary.gramsQuantity, purchaseSummary.unitOfMeasure)}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="black-text">
                                  Reference Instrument
                                </th>
                                <td className="grey-text">{purchaseSummary.referenceInstrument}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="black-text">
                                  Total Price
                                </th>
                                <td className="grey-text">{`${getSymbolFromCurrency(
                                  purchaseSummary?.currency || 'GBP'
                                )} ${purchaseSummary.price}`}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="black-text">
                                  Order Reference Number
                                </th>
                                <td className="grey-text">{purchaseSummary.orderNumber}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    <div className="wr-purchase-summery-button my-3">
                      <button className="btn btn-dark purchase-summery-button" onClick={lockAwayMoreCarbonHandler}>
                        Lock away more carbon
                      </button>
                    </div>

                    <div className="my-3">
                      <hr className="dash-lines" />
                    </div>

                    <div className="wr-purchase-summery-link my-4">
                      <p className="purchase-summery-link-text">Interested in learning more?</p>
                      <div className="links-container">
                        <a
                          href="https://www.c2zero.net"
                          className="purchase-summery-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Visit C2Zero.net
                        </a>
                        <a
                          href="https://www.youtube.com/watch?v=iTu2Bv5q6lc"
                          className="purchase-summery-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Watch our video
                        </a>
                      </div>
                    </div>

                    <div className="footer-wrapper mb-5">
                      <p className="text-left copyright-text">Copyright © {new Date().getFullYear()} C2Zero.</p>
                      <a
                        href="https://www.c2Zero.net/privacy"
                        className="privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
