import { addHeaders } from '../util/apiHelper';
import { appProperties } from '../util/appProperties';

/**
 * Creates a payment intent.
 *
 * @param {string} emailForPaymentReceipt - The email address for the payment receipt.
 * @param {string} emailForEcertificate - The email address for the e-certificate.
 * @param {number} weight - The weight of the item.
 * @param {boolean} isNewsLetterSubscribed - Indicates whether the user is subscribed to the newsletter.
 * @param {string} applicationHostedRegion - The region where the application is hosted.
 * @param {string} businessLocatedCountry - The country where the business is located.
 * @param {string} vatRegistrationNumber - The VAT registration number of the business.
 * @param {string} customerName - The name of the person who purchase the certificate.
 * @param {string} businessName - The name of the business if they have any.
 * @param {boolean} IsAutomaticallyLogCarbon - Indicates whether the user wants to automatically claim and log the carbon.
 * @param {Object} instance - The MSAL instance of the application.
 * @return {Object} The payment intent data.
 */
export const createPaymentIntent = async (
  recipient,
  emailForPaymentReceipt,
  emailForEcertificate,
  weight,
  isNewsLetterSubscribed,
  applicationHostedRegion,
  businessLocatedCountry,
  vatRegistrationNumber,
  customerName,
  businessName,
  IsAutomaticallyLogCarbon,
  instance
) => {
  const apiEndpoint = appProperties.apiHost + '/Consumers/getPaymentIntent';
  try {
    const headers = await addHeaders(true, instance); // Set the Content-Type to JSON
    const body = JSON.stringify({
      recipient,
      emailForPaymentReceipt,
      emailForEcertificate,
      weightInGrams: Number(weight) * 1000,
      isNewsLetterSubscribed,
      applicationHostedRegion,
      businessLocatedCountry,
      vatRegistrationNumber,
      customerName,
      businessName,
      IsAutomaticallyLogCarbon
    });

    const options = {
      method: 'POST',
      headers: headers,
      body: body
    };
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      // Handle specific HTTP errors
      if (response.status === 401) {
        throw new Error('Unauthorized - Please log in.');
      } else if (response.status === 404) {
        throw new Error('Resource not found.');
      } else {
        throw new Error(`Request failed with status: ${response.status} - ${response.statusText}`);
      }
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    // Log and rethrow the error for the calling code to handle
    console.log('ERRCreatePaymentIntent: ', error);
    throw error;
  }
};

/**
 * Creates a payment reference for direct bank payment.
 *
 * @param {string} emailForPaymentReceipt - The email address for the payment receipt.
 * @param {string} emailForEcertificate - The email address for the e-certificate.
 * @param {number} weight - The weight of the item.
 * @param {boolean} isNewsLetterSubscribed - Indicates whether the user is subscribed to the newsletter.
 * @param {string} applicationHostedRegion - The region where the application is hosted.
 * @param {string} businessLocatedCountry - The country where the business is located.
 * @param {string} vatRegistrationNumber - The VAT registration number of the business.
 * @param {string} customerName - The name of the person who purchase the certificate.
 * @param {string} businessName - The name of the business if they have any.
 * @param {boolean} IsAutomaticallyLogCarbon - Indicates whether the user wants to automatically claim and log the carbon.
 * @param {Object} instance - The MSAL instance of the application.

 * @return {Object} The payment reference data.
 */
export const makeDirectBankTransfer = async (
  recipient,
  emailForPaymentReceipt,
  emailForEcertificate,
  weight,
  isNewsLetterSubscribed,
  applicationHostedRegion,
  businessLocatedCountry,
  vatRegistrationNumber,
  customerName,
  businessName,
  IsAutomaticallyLogCarbon,
  instance
) => {
  const apiEndpoint = appProperties.apiHost + '/Consumers/directPayment';

  try {
    const headers = await addHeaders(true, instance);
    const body = JSON.stringify({
      recipient,
      emailForPaymentReceipt,
      emailForEcertificate,
      weightInGrams: Number(weight) * 1000,
      isNewsLetterSubscribed,
      applicationHostedRegion,
      businessLocatedCountry,
      vatRegistrationNumber,
      customerName,
      businessName,
      IsAutomaticallyLogCarbon,
      isDirectPay: true
    });

    const options = {
      method: 'POST',
      headers: headers,
      body: body
    };
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      // Handle specific HTTP errors
      if (response.status === 401) {
        throw new Error('Unauthorized - Please log in.');
      } else if (response.status === 404) {
        throw new Error('Resource not found.');
      } else {
        throw new Error(`Request failed with status: ${response.status} - ${response.statusText}`);
      }
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    // Log and rethrow the error for the calling code to handle
    console.log('ERRCreatePaymentReference: ', error);
    throw error;
  }
};

export const createOrderWithDirectPaymentRef = async (paymentReference, instance) => {
  const apiEndpoint = appProperties.apiHost + '/Consumers/directPaymentOrder';

  try {
    const headers = await addHeaders(true, instance);
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(paymentReference)
    };
    const response = await fetch(apiEndpoint, options);
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized - Please log in.');
      }
      if (response.headers.get('Content-Type').startsWith('application/json')) {
        const errorResponse = await response.json();
        if (errorResponse?.status == 402) {
          throw new Error(`Your order has already been initiated.`);
        } else {
          throw new Error(`API Error: ${errorResponse?.title}`);
        }
      }
      throw new Error(`API Error: create Order With Direct PaymentRef`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error('Error creating direct payment order:', error);
    throw error;
  }
};
