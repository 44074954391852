import React, { useContext } from 'react';
import logo from '../../assets/images/logo_white.svg';
import { SigninSignup } from '../../components/SigninSignup';
import AppContext from '../../contexts/AppContext';

const Home = ({ instance }) => {
  const applicationContext = useContext(AppContext);
  const { isECertificateFeatureAllowed } = applicationContext;

  return (
    <div>
      <div className="authentication">
        <div className="container">
          <div className="authentication-wrapper">
            <div className="welcome">
              <div className="welcome-content">
                <img src={logo} alt="c20 logo" className="logo" />
                <h2>{isECertificateFeatureAllowed ? `Welcome to C4S by C2Zero` : `Welcome to C2Zero`} </h2>
                <p className="saying-text">
                  {isECertificateFeatureAllowed
                    ? `Carbon Storage and Cancellation Service.`
                    : `We’re hijacking the carbon system for good.`}
                </p>
              </div>
            </div>
            <SigninSignup instance={instance} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
